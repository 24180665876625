import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import marked from "marked"
import aboutherobottom from "../images/hero-page-bottom-img.png"

const StoriesTemplate = ({ data }) => {
	const person = data.sanitySuccessStories
	return (
		<Layout>
			<Seo
				title={person.name}
				description={person.excerpt ? person.excerpt.substring(0, 156) : null}
			/>
			<div className="page-headline">
				<div className="hero-headline rewards-program-page">
					<div className="container">
						<div className="section-heading text-center">
							<div className="hero-body">
								<h1 className="hero-page-title mb-4">{person.name}</h1>
								<p className="mb-0 text-white">{person.department}</p>
								<h2 className="hero-subtitle text-white">{person.position}</h2>
							</div>
						</div>
					</div>
				</div>
				<img
					src={aboutherobottom}
					className="hero-bottom-img img-fluid"
					alt="Our Team"
				/>
			</div>
			<div className="page-content pb-280">
				<div className="container">
					<div
						className="mx-auto border-rounded mb-4"
						style={{
							width: 400,
							height: 400,
							backgroundImage: `url(${person?.image?.asset?.fluid?.srcWebp})`,
							backgroundSize: "cover",
							backgroundRepeat: "no-repeat",
							backgroundPosition: "center center",
							borderRadius: "50%",
						}}
					></div>
					<div dangerouslySetInnerHTML={{ __html: marked(person.bio) }} />
				</div>
			</div>
		</Layout>
	)
}

export default StoriesTemplate

export const storyQuery = graphql`
	query storyQuery($id: String!) {
		sanitySuccessStories(id: { eq: $id }) {
			id
			name
			department
			excerpt
			position
			bio
			image {
				asset {
					fluid {
						srcWebp
					}
				}
			}
		}
	}
`
